.App {
  background-color: #fafafa;
  color: #333;
}

.App .default-button {
  background: #333;
  border: 1px solid #333;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.App a {
  color: #61dafb;
}

.App .default-button:hover {
  opacity: 0.9;
}

.App-header {
  height: 50px;
  padding: 20px;
  color: white;
}

.App-logo {
  margin-left: 0;
}

.App-icon {
  display: inline-block;
  text-align: center;
  background-color: #333;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.App-name {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bolder;
  vertical-align: super;
  color: #333;
}

.App-logo img {
  margin: 5px auto;
}

.App-body {
  text-align: center;
  padding: 50px;
}

.hide {
  display: none;
}

/* mobile view */
@media (max-width: 720px) {
  .App-name {
    margin-left: 8px;
    font-size: 25.2px;
  }
}

/* tablet view */
@media (min-device-width: 600px) and (max-device-width: 1280px) {
  .App-name {
    margin-left: 8px;
    font-size: 32px;
  }
}
