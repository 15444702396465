.searchInput button {
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    display: block;
    margin: 10px auto;
    width: 320px;
}

.searchInput button.img-select {
    color: black;
    background-color: #eee;
}

.searchInput button:hover {
    opacity: 0.9;
}

.searchInput button span {
    vertical-align: super;
}

.credenitals-input {
    display: block;
    padding: 10px;
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 5px;
    margin: 10px auto;
    width: 300px;
}