.query-image {
    margin: 80px 0 10px 0;
    text-align: left;
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 250px;
    height: 450px;
    display: inline-block;
    margin: 5px 15px;
}

.product-card img {
    max-width: 250px;
}

.product-card .card-body {
    padding: 10px;
    text-align: left;
    font-size: 14px;
}

.product-card .card-body .product-id {
    color: red;
    font-size: 22px;
}